.react-datepicker__input-container input {
    padding: 5px;
    padding-left: 10px;
    border-radius: 30px;
    border-width: 1px;
    font-size: 14px;
}

.datepickerWrapper {
    z-index: 100;
}